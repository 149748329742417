import React, { useState, useEffect } from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import { withRouter ,useHistory} from "react-router-dom";
import { connect } from 'react-redux';
import LoaderSpinner from  '../../assets/images/loader.svg'
import TimezonePicker from '../timezonepicker/TimeZonePicker'
import {getorgDetail,EditOrg} from '../actionMethods/actionMethods'
import {Switch,Select, Slider } from 'antd';
import ReactModal from 'react-modal';
import {Radio, Button } from 'antd';
import {Steps } from 'antd';
import moment from 'moment';
const { Option } = Select;

function Editorganization(props) {
	const history = useHistory();
    const [loader,SetLoader] =useState(true);
	const [timezone, setTimezone] = useState('');
    let userDetails = localStorage.getItem('userLoginDetailsTraceAdmin')!=null && localStorage.getItem('userLoginDetailsTraceAdmin')!='loggedIn'?JSON.parse(localStorage.getItem('userLoginDetailsTraceAdmin')):[];
    let session = userDetails.session!=null?userDetails.session:'ezrsrdfghawtsetrrAZSXCxfa';
	 
   const { Step } = Steps;
   const [current, setCurrent] = React.useState(0);
	const [switchValue, setSwitchValue] = useState(false);
    const[orgname,Setorgname]= useState("");
    const[orgdescription,Setorgdescription] =useState("");
    const[orgurl,Setorgurl] = useState("");
    const[utctimezone,Setutctimezone] =useState("");
    const[utctimezonename,Setutctimezonename] =useState("");
    const [org_name,Setorg_name]=useState('');
    const [org_nameError,Setorg_nameError]=useState('');
    const [orgDetail,SetorgDetail]=useState([]);
    const[multisession,Setmultisession]=useState("")
    const[orgid,Setorgid]=useState("");
    const [hoverText, setHoverText] = useState('');
    const [timezoneData, setTimezoneData] = useState({
      utc_hr: '',
      utc_min: '',
      timezone: ''
    });
    const [startHour, setStartHour] = useState(null);
    const [endHour, setEndHour] = useState(null);
    const [startMinute, setStartMinute] = useState(null);
const [endMinute, setEndMinute] = useState(null);

    const timeFields = [
      { label: "Early Check-in Min", key: "early_checkin_low_time"},
      { label: "Early Check-in Max", key: "early_checkin_high_time" },
      { label: "Late Check-in Min", key: "late_checkin_low_time" },
      { label: "Late Check-in Max", key: "late_checkin_high_time" },
      { label: "Shift Changes Min", key: "shift_changes_low_time" },
      { label: "Shift Changes Max", key: "shift_changes_high_time" },
      { label: "On-time Min", key: "ontime_low" },
      { label: "On-time Max", key: "ontime_high" },
      { label: "Under Time Offset", key: "under_time_offset" },
      { label: "Over Time Offset", key: "over_time_offset" }  
    ];

        const [times, setTimes] = useState(
    timeFields.reduce((acc, field) => ({ ...acc, [field.key]: 0 }), {})
  );
  
    const formatHour = (hour) => {
      return `${hour < 10 ? '0' : ''}${hour}:00:00`;
    };

  
    const convertToMinutes = (time) => {
      const [hours, minutes, seconds] = time.split(':').map(Number);
      return hours * 60 + minutes + seconds / 60;
  };
    const handleStartHourChange = (value) => {
      setStartHour(value);
    };
  
    const handleEndHourChange = (value) => {
      setEndHour(value);
    };

  
  const formatMinutesToTime = (minutes) => {
    const hours = Math.floor(minutes / 60).toString().padStart(2, '0');
    const mins = (minutes % 60).toString().padStart(2, '0');
    return `${hours}:${mins}:00`;
  };
  const handleSliderChange = (key, value) => {
    setTimes({ ...times, [key]: value });
  };


    useEffect(()=>{
        
        let requestBody={}
        requestBody.session=session
        requestBody.org_id= props.orgDetail.id
  
        getorgDetail(requestBody).then(res=>{
         
          if(res.status==200 || res.code== 200){
            SetorgDetail(props.orgDetail);
            Setorgname(res.data.name);
            Setorgdescription(res.data.description);
            setStartHour(res.data.start_hour.split(":")[0])
            setStartMinute(res.data.start_hour.split(":")[1]+":"+res.data.start_hour.split(":")[2])
            setEndHour(res.data.end_hour.split(":")[0])
            setEndMinute(res.data.end_hour.split(":")[1]+":"+res.data.end_hour.split(":")[2])
            Setorgurl(res.data.logo_url);

            let timeValue = `${res.data.timezone} +${res.data.utc_hr}:${res.data.utc_min}`;
            Setutctimezonename(res.data.timezone)
            Setutctimezone(`+${res.data.utc_hr}:${res.data.utc_min}`)
            setTimezone(timeValue);
            switchdefault(res.data)
            Setorgid(orgDetail.id)
            
             
              
              setTimes(timeFields.reduce((acc, field) => ({ ...acc, [field.key]: convertToMinutes(res.data.org_settings[field.key])  }), {}))

             

            
           //handleSwitchClick(res.data);
            SetLoader(false)


            
            localStorage.setItem("timezonename", res.data.timezone)
          }
          if(res.status==300){
            localStorage.removeItem('isLoggedInGatewayTraceAdmin')
            localStorage.removeItem('userLoginDetailsTraceAdmin')
            props.history.push(`/login`)
        }
        }).catch()
  
      },[props.orgDetail])

    

    //   const handleSwitchClick = (res) => {
    //     setSwitchValue(res.data.org_settings.multi_session === "True" ? true : false);
    // };
      
	const handleSwitchClick = (props) => {
        setSwitchValue(!switchValue)
        if(switchValue == true){

            Setmultisession("True")
           
            return true
        }else{
            Setmultisession("False")
           
            return false
        }
	};

    const switchdefault = (props) => {
        Setmultisession(String(props.org_settings["multi_session"]));
           if (String(props.org_settings["multi_session"]) ==="True"){
            setSwitchValue(true)
           }else{
            setSwitchValue(false)
           }
	};
  
    const handleChange = (event) => {
        setTimezone(event.target.value);
    }
	const handleSelect = (name, timeLocale) => {
        Setutctimezone(timeLocale)
        Setutctimezonename(name)
        setTimezone(name + ' ' + timeLocale);
    }


    useEffect(()=>{
//       const id2 = setInterval(function(){
//         let timeZone =localStorage.getItem('timeZone')?JSON.parse(localStorage.getItem('timeZone')):{};
//         // here check new value
//         console.log("before cond",timeZone,timezoneData);
        
//         if (timeZone.timezone ) {
//           console.log("Condition met!");
//           console.log(timeZone);
//           console.log("after cond",timeZone,timezoneData.timezone);

//           var now = moment.utc();
//           var timezone1 = moment(now).tz(timeZone.timezone).format('Z');

//           let timezone = timeZone.timezone;
//           console.log('timezone: ', timezone);
         
//          let timezoneHour = String(timezone1).split(':')[0].substring(1);
        
//          console.log('timezoneHour: ', timezoneHour);
//          let timezoneMinute = String(timezone1).split(':')[1];
       

//           console.log('timezoneMinute: ', timezoneMinute);
          
//           setTimezoneData({
//            timezoneMinute: timezoneMinute,
//            timezone: timezone,
//            timezoneHour: timezoneHour,
//          });       
// console.log("timezoneData updated:", timezoneData);
//         }         
        
//       },1000)
          const id2 = setInterval(function() {
            const timeZone = localStorage.getItem('timeZone') ? JSON.parse(localStorage.getItem('timeZone')) : {};
            
            if (timeZone.timezone && timeZone.timezone !== timezoneData.timezone) {
              

              const now = moment.utc();
              const timezone1 = moment(now).tz(timeZone.timezone).format('Z');
              const timezone = timeZone.timezone;
              const timezoneHour = String(timezone1).split(':')[0].substring(1);
              const timezoneMinute = String(timezone1).split(':')[1];

              setTimezoneData({
                timezoneMinute: timezoneMinute,
                timezone: timezone,
                timezoneHour: timezoneHour,
              });
              
            } else {
              
            }
          }, 1000);

      const id =	setInterval(function(){
          var elem = document.querySelectorAll('.timezone-map polygon');
          //console.log("ele ",elem)
          var elem2 = document.querySelectorAll('.select2-results__option');
          

          for(var i = 0; i < elem2.length; i++) {
          
            elem2[i].addEventListener('click', function(e){
              //var contactName = e.currentTarget

             
           
               });
            //clearInterval(id);
          }
          for(var i = 0; i < elem.length; i++) {
              //console.log(elem)
              clearInterval(id);
              //var zone_name1 =  moment.tz.guess();
              elem[i].addEventListener('click', function(e){
                  //var contactName = e.currentTarget
                  var now = moment.utc();
                  var timezone1 = moment(now).tz(e.currentTarget.dataset.timezone).format('Z');
                  //console.log("timezone1 ",timezone1);
                  console.log("data-timezone" ,e.currentTarget.dataset,moment.tz(e.currentTarget.dataset.timezone).format('dd-mm-yyyy hh:mm:ss'),
               );
               let timezone = e.currentTarget.dataset.timezone;
              
              
              //let timezoneHour =  moment.tz(e.currentTarget.dataset.timezone).format('hh');
              let timezoneHour = String(timezone1).split(':')[0].substring(1);
             
           
              //let timezoneMinute = moment.tz(e.currentTarget.dataset.timezone).format('mm');
              let timezoneMinute = String(timezone1).split(':')[1];
            

               
               
               setTimezoneData({
               
                timezoneMinute: timezoneMinute,
                timezone: timezone,
                timezoneHour: timezoneHour,
                
              });       
                       
              }, false);
          }
         
  
  
      },1000)
  
  
      return () => {
          clearInterval(id);	
          clearInterval(id2)
        };
  
    },[timezoneData,timezone])
   
  
    function EditOrghandler(event){

        event.preventDefault();
  
        if(orgname==''|| orgname.trim().length==0){
          Setorg_nameError('org Name is required');
          window.scroll({
            top: 0, 
            left: 0, 
            behavior: 'smooth'
          });
        }else if(orgname.length > 255){
          Setorg_nameError('org Name length should be less than 255 character');
          window.scroll({
            top: 0, 
            left: 0, 
            behavior: 'smooth'
          });
        }
        else if(orgname!=''){
        //   SetLoader(true);
          let requestBody={}
          requestBody.org_name=orgname.trim()
          requestBody.description=orgdescription
          requestBody.logo_url= orgurl
          requestBody.start_hour=startHour+":"+startMinute
          requestBody.end_hour=endHour+":"+startMinute
          // requestBody.utc_hr= utctimezone.split(':')[0].substring(1)
          // requestBody.utc_min= utctimezone.split(':')[1]
          // requestBody.timezone= utctimezonename
         
          if (String(timezoneData.timezoneHour) != ""){
            requestBody.utc_hr = timezone.split(' ')[1].split(':')[0].substring(1);
          }
          else{
            requestBody.utc_hr = String(timezoneData.timezoneHour);
          }

          if (String(timezoneData.timezoneMinute) != "undefined"){
            requestBody.utc_min = String(timezoneData.timezoneMinute);
            }
            else{
              requestBody.utc_min = timezone.split(' ')[1].split(':')[1];
            }

          if ( timezoneData.timezone != ""){
            requestBody.timezone = timezoneData.timezone;
            }
            else{
              requestBody.timezone = utctimezonename;
            }
          requestBody.org_settings= {"multi_session" : switchValue===true?"True":"False"}
          requestBody.session= userDetails.session
          requestBody.org_id = String(orgDetail.id)
          timeFields.map((field) => ( requestBody[`${field.key}`] = formatMinutesToTime(times[field.key])))
        
  
          EditOrg(requestBody).then(res=>{
           
               SetLoader(false);
              if(res.status==200){
                props.modalSucessHandler(<h4>Organization is updated</h4>,res.message)
                localStorage.removeItem('timezonename')
            
             props. SetModalSucess(true);
            //   SetLoader(true)
             
              }else{
                props.modalErrorHandler(<h4>{res.message}</h4>,'Organization is not  updated')
                localStorage.removeItem('timezonename')
         
           props.SetModalError(true)
            // SetLoader(false)
              }
              if(res.status==300){
                localStorage.removeItem('isLoggedInGatewayTraceAdmin')
                localStorage.removeItem('userLoginDetailsTraceAdmin')
                localStorage.removeItem('timezonename')
                props.history.push(`/login`)
             }  
          }).catch();
        
        }else{
          SetLoader(false);
          Setorg_nameError('Required');
        }
  
      }


   
 const steps = [
		{
		  title: 'Organization Info',
		  step: '01',
		  content: 'First-content',
		},
		{
		  title: 'TimeZone',
		  step: '02',
		  content: 'Second-content',
		},
    {
		  title: 'Working Day',
		  step: '03',
		  content: 'Third-content',
		},
    {
		  title: 'Compliance',
		  step: '04',
		  content: 'Fourth-content',
		},
	  ];
          
        //   function onChangeStep(step){
        // var validRegex = /^([a-zA-Z0-9_.+-])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;
        // // if(current==0){
        // // 	if(firstname==''){
        // // 	SetFirstNameError('First name is required');
        // // 	}else if(firstname.length > 255){
        // // 		SetFirstNameError('Length of first name should be less than 255 character');
        // // 	}
        // // 	else if(password==''){
        // // 		SetEmailError('');
        // // 		SetPasswordError('Password is required');
        // // 	}else if(password.length> 32){
        // // 		SetEmailError('');
        // // 		SetPasswordError('Length of password should be less than 32 character ');
        // // 	}
        // // 	else if(description.length> 255){
        // // 		SetPasswordError('');
        // // 		SetDescriptionError('Length of designation should be less than 255 character ');
        // // 	}
        // // 	else{
        // // 		setCurrent(step);	
        // // 	}
    
        // // }else{
        //   setCurrent(step);
        // // }
        
        // }
        function onChangeStep(step) {
          // Assuming you have already declared validRegex and other variables above
        
          if (current === 0) {
            if (orgname === '') {
              Setorg_nameError('org name is required');
            } else if (orgname.length > 255) {
              Setorg_nameError('Length of organization name should be less than 255 characters');
            }
            // } else if (orgdescription === '') {
            //   SetdescriptionError('org description is required');
            // } 
            // else if (orgurl === '') {
            //   SetlogoError('Logo URL is required');
            // } 
            else {
              setCurrent(step);
            }
          } else {
            setCurrent(step);
          }
        }

    return(
      <>
      {loader ? (<div className="loaderContainer loaderContainerSmall"><img src={LoaderSpinner} /></div>
      ) : (<>
          <div className='modalHeader'>
                  <h4>Edit Organization</h4>
                  <div className='closeMark' onClick={()=>props.handleCloseModal()}>x</div>
              </div>
          <div className="modalBody">
          <Steps current={current} onChange={onChangeStep}>
        {steps.map(item => (
        <Step key={item.title} title={item.title} />
        ))}

      </Steps>
              {current==0?<div className="steps-content step-user-info">
                  <div className="input-container">
                      <div className="input-item">
                          <label>Organization Name</label>
                          <input
                              type="text"
                              className="form-control input-field"
                              value={orgname}
                              onChange={(e)=>{{Setorgname(e.target.value)}}}
                          />
                          {/* {org_nameError!=''?<div className='errorMessage'>{org_nameError}</div>:""} */}
                      </div>
                      <div className="input-item">
                          <label>Organization Description</label>
                          <input
                              type="text"
                              className="form-control input-field"
                              onChange={(e)=>{{Setorgdescription(e.target.value)}}}
                              value={orgdescription}
                          />
                      </div>
                      <div className="input-item">
                          <label>Organization Logo URL</label>
                          <input
                              type="text"
                              className="form-control input-field"
                              onChange={(e)=>{{Setorgurl(e.target.value)}}}
                              value={orgurl}
                          />
                      </div>
                      {/* <div className="input-item">
                      <label>Time zone</label>
                      <input type="text" value={timezone} onChange={handleChange} className='form-control'   />
                      </div> */}
                      <div className='input-item'>
                                      <label style={{marginBottom:"5px"}}>Multi Session</label>
                                      <Switch  style={{marginLeft:"20px"}} onClick={handleSwitchClick} value={switchValue} checked={switchValue} />
                              </div>
                          <div className="timezone-picker-container">
                              {/* <TimezonePicker
                                  onSelect={handleSelect}
                                  className="timezone-picker"
                              /> */}
                          
                      </div>
                  </div></div>:""}
                  <div className={current==1?"steps-content":"hide"}style={{display:current==1?"block":"none"}}>
                  <div id="infoHover" style={{position:'absolute',width:'auto',background:"rgb(0,0,0,0.7)",color:"#fff",padding:"10px",borderRadius:"5px",marginRight:"30px"}}>{hoverText}</div>
          <div className='timeZoneArea' style={{height:"470px"}}></div>

      </div>

              

      {current==2?<div className="steps-content step-user-info">
                 <Row>
						<Col lg={3}>
							<div className='field-row'>
              <label>Start Hour</label>
              

              <Select
        placeholder="Start Hour"
        onChange={handleStartHourChange}
        className={'form-control'}
        value={startHour}
      >
        {Array.from({ length: 24 }, (_, i) => (
          <Option key={i} value={i}>
            {formatHour(i)}
          </Option>
        ))}
      </Select>
     
              </div>
						</Col>
            <Col lg={3}>
							<div className='field-row'>
              <label>Start Minute</label>
              <Select
        placeholder="Start Minute"
        // onChange={handleStartHourChange}
                onChange={(val)=>{console.log(val);setStartMinute(val)}}
        className={'form-control'}
        value={startMinute}
      >
        {Array.from({ length: 60 }, (_, i) => (
          <Option key={i} value={i.toString().padStart(2,"0")+":00"}>
            {i.toString().padStart(2,"0")}
          </Option>
        ))}
      </Select>
     
              </div>
						</Col>
						<Col lg={3}>
							<div className='field-row'>
              <label>End Hour</label>
            
              <Select
        placeholder="End Hour"
        onChange={handleEndHourChange}
        className={'form-control'}
        value={endHour}
      >
        {Array.from({ length: 24 }, (_, i) => (
          <Option key={i} value={i}>
            {formatHour(i)}
          </Option>
        ))}
      </Select>
 
  </div>
  </Col>
  <Col lg={3}>
  						<div className='field-row'>
              <label>End Minute</label>
              <Select
        placeholder="End Minute"
        onChange={(val)=>setEndMinute(val)}
        className={'form-control'}
        value={endMinute}
      >
        {Array.from({ length: 60 }, (_, i) => (
          <Option key={i} value={i.toString().padStart(2,"0")+":00"}>
            {i.toString().padStart(2,"0")}
          </Option>
        ))}
      </Select>
     
				</div>	
  </Col>

			

					
					</Row>	
                    </div>:""}


      {current==3?<div className="steps-content step-user-info">
<Row>
        {timeFields.map((field) => (
        <Col key={field.key} lg={4} className='fieldSpace' style={{ marginBottom: 0 }}>
          <div className="field-row field-row-box">
            <label>{field.label} <strong>({formatMinutesToTime(times[field.key])})</strong></label>
            
            <Slider
              min={0}
              max={240}
              value={times[field.key]}
              marks={{0:"00:00",60: "01:00", 120: "02:00", 180: "03:00", 240: "04:00"}}
              // tooltipVisible={true}
              tooltipPlacement='bottom'
              tooltip={{ className: 'smallTooltip',
                overlayClassName: 'smallTooltipOverlay', formatter: (value) => formatMinutesToTime(value) }}
                classNames={'smallTooltipParent'}
              onChange={(value) => handleSliderChange(field.key, value)}
              
            />
            
          </div>
        </Col>
      ))}
     </Row>          	
                    </div>:""}
              <div className="steps-action">
              {/* <button type="submit" className="btn btn-primary"  onClick={AddOrghandler} >Next Step</button> */}

              {/* <button type="submit" className="btn btn-primary btn-50"  onClick={onChangeStep(1)} >Next Step</button> */}
              {current > 0 && (
        <Button style={{ margin: '0 8px' }} onClick={() => setCurrent(prev=>prev-1)} className={'btn btn-primary'}>
          Back
        </Button>
        )}
                  {current < steps.length - 1 &&  (
        <Button type="primary" onClick={() => onChangeStep(current + 1)} className={'btn btn-primary'}>
          Next
        </Button>
        )}

        {current == steps.length -1 &&  (
        <Button type="primary" onClick={(event) => EditOrghandler(event)} className={'btn btn-primary'}>
          Update Organization
        </Button>
        )}

              </div>
              
          
          </div> 
          
          </>)}
   
            
  </>
    )
}
const mapStateToProps = (state) => ({
    language: state.dashboard.selectedLangaugeValue
})

export default connect(mapStateToProps, {  })(withRouter(Editorganization))